.btn {
  display: block;
  background: #5a5c5b;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  line-height: 16px;
  padding: 11px 32px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 3px solid #5a5c5b;
  outline: 0;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
}

.btn:disabled {
  cursor: not-allowed;
  background: rgba(128, 128, 128, 0.5);
  border-color: rgba(128, 128, 128, 0.2);
  box-shadow: unset;
}

.btn:disabled:active {
  transform: unset;
}
