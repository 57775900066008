@import url(https://fonts.googleapis.com/css?family=Montserrat);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  font-family: 'Dancing Script', cursive;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(238, 174, 202, 1) 5%,
    rgba(148, 187, 233, 1) 90%
  );
  color: white;
  align-items: center;
  justify-content: center;
}
.mob {
  padding: 0 20px;
}
.mob img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  position: relative;
  display: block;
}

.test {
  background: url(/static/media/stars.a28317b9.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-bottom: 15px;
}
.test .connector {
  text-align: center;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0;
}
.test img {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  form {
    min-height: 110px;
    align-items: center;
  }
  form .input--text {
    margin: 0;
    width: 100%;
  }
  form .btn {
    width: 100%;
  }
}

.Countdown {
  font-size: 2em;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Countdown-col {
  text-align: center;
  margin-right: 5px;
}

.input--text {
  line-height: 22px;
  padding: 10px 12px;
  border-radius: 3px;
  border: 1px solid gray;
  margin-right: 5px;
}

.card {
  display: flex;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: #fff;
  flex-direction: column;
  padding: 15px;
  max-width: 80%;
}

.btn {
  display: block;
  background: #5a5c5b;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  line-height: 16px;
  padding: 11px 32px;
  -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 3px solid #5a5c5b;
  outline: 0;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
}

.btn:disabled {
  cursor: not-allowed;
  background: rgba(128, 128, 128, 0.5);
  border-color: rgba(128, 128, 128, 0.2);
  box-shadow: unset;
}

.btn:disabled:active {
  -webkit-transform: unset;
          transform: unset;
}

.App {
  text-align: center;
}

.App-intro {
  font-size: 3em;
  text-align: center;
  margin: 0;
}

.App-intro:first-of-type {
  margin-top: 15px;
}
.App-intro:last-of-type {
  margin-bottom: 5px;
}

form {
  display: flex;
  flex-wrap: wrap;
}

.bunny {
  background: url(/static/media/bunny.497c1711.jpg) no-repeat;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
}
.error {
  color: red;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.loader {
  background: url(/static/media/loader.4c42f06a.svg);
  width: 200px;
  height: 200px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

