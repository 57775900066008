.Countdown {
  font-size: 2em;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Countdown-col {
  text-align: center;
  margin-right: 5px;
}
