@import url('https://fonts.googleapis.com/css?family=Montserrat');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  font-family: 'Dancing Script', cursive;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(238, 174, 202, 1) 5%,
    rgba(148, 187, 233, 1) 90%
  );
  color: white;
  align-items: center;
  justify-content: center;
}
.mob {
  padding: 0 20px;
}
.mob img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  position: relative;
  display: block;
}

.test {
  background: url('img/stars.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 15px;
}
.test .connector {
  text-align: center;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0;
}
.test img {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  form {
    min-height: 110px;
    align-items: center;
  }
  form .input--text {
    margin: 0;
    width: 100%;
  }
  form .btn {
    width: 100%;
  }
}
