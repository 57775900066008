.App {
  text-align: center;
}

.App-intro {
  font-size: 3em;
  text-align: center;
  margin: 0;
}

.App-intro:first-of-type {
  margin-top: 15px;
}
.App-intro:last-of-type {
  margin-bottom: 5px;
}

form {
  display: flex;
  flex-wrap: wrap;
}

.bunny {
  background: url('img/bunny.jpg') no-repeat;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
}
.error {
  color: red;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.loader {
  background: url('img/loader.svg');
  width: 200px;
  height: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
